import { HttpClient as AngularHttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { deserializeAndValidateResult } from './deserializeAndValidateResult';
import { ReturnType, ReturnValue } from './http-client-types';
import { HttpClient } from './http-client.service';
import { Options } from './http-options';
import { serialize } from './serializer';

@Injectable({
    providedIn: 'root',
})
export class SerializingHttpClient extends HttpClient {
    constructor(private http: AngularHttpClient) {
        super();
    }

    get<S>(
        returnType: ReturnType<S>,
        path: string,
        options?: Options,
    ): Observable<ReturnValue<S>> {
        return this.http
            .get(path, options)
            .pipe(deserializeAndValidateResult(returnType));
    }

    put<S>(
        returnType: ReturnType<S>,
        path: string,
        body: any,
        options?: Options,
    ): Observable<ReturnValue<S>> {
        const json = serialize(body);

        return this.http
            .put(path, json, options)
            .pipe(deserializeAndValidateResult(returnType));
    }

    post<S>(
        returnType: ReturnType<S>,
        path: string,
        body: any,
        options?: Options,
    ): Observable<ReturnValue<S>> {
        const json = serialize(body);

        return this.http
            .post(path, json, options)
            .pipe(deserializeAndValidateResult(returnType));
    }

    delete<S>(
        returnType: ReturnType<S>,
        path: string,
        options?: Options,
    ): Observable<ReturnValue<S>> {
        return this.http
            .delete(path, options)
            .pipe(deserializeAndValidateResult(returnType));
    }
}
