import { classToPlain, plainToClass, plainToClassFromExist } from 'class-transformer';
import { ReturnType, ReturnValue } from './http-client-types';
import { Array } from './models/array.model';
import { Page } from './models/page.model';

export function serialize(data: any | null): Record<string, any> {
    return classToPlain(data);
}

export function deserialize<S>(
    data: any,
    clazz: ReturnType<S>,
): ReturnValue<S> {
    if (!data || null === clazz) {
        return data;
    }

    if (clazz instanceof Page || clazz instanceof Array) {
        return plainToClassFromExist(clazz, data);
    }

    return plainToClass(clazz, data);
}
