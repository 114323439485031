import { isObject, validate } from 'class-validator';
import { from, of, OperatorFunction, pipe, throwError } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ReturnType } from './http-client-types';
import { Array as ArrayOf } from './models/array.model';
import { Page } from './models/page.model';
import { deserialize } from './serializer';

function throwTypeError(value: any, type: string) {
    if (null === value) {
        throw new Error(`Expected value to be of type "${type}". Got "null"`);
    }

    const valueType = typeof value;

    throw new Error(`Expected value to be of type "${type}". Got "${valueType}"`);
}

function assertValueIsExpectedType<S>(returnType: ReturnType<S>): OperatorFunction<S, S> {
    return tap((value) => {
        if (returnType instanceof Page) {
            if (!(value instanceof Page)) {
                throwTypeError(value, 'Page');
            }

            return;
        }

        if (returnType instanceof ArrayOf) {
            if (!(value instanceof ArrayOf)) {
                throwTypeError(value, 'Array');
            }

            return;
        }

        if (null !== returnType) {
            if (!(value instanceof returnType)) {
                throwTypeError(value, returnType.name);
            }
        }
    });
}

function validateAndThrow<S>(): OperatorFunction<S, S> {
    return switchMap(
        (data) => {
            if (null === data || !isObject(data)) {
                return of(data);
            }

            return from(validate(data))
                .pipe(
                    switchMap((errors) => {
                        return errors.length > 0
                            ? throwError(new Error(errors.join('\n').trim()))
                            : of(data);
                    }),
                );
        },
    );
}

function unwrapArrays<S>(): OperatorFunction<S, S>;
function unwrapArrays<S, T extends Page<S>>(): OperatorFunction<T, Page<S>>;
function unwrapArrays<S, T extends ArrayOf<S>>(): OperatorFunction<T, S[]>;

function unwrapArrays<S, T extends ArrayOf<S>>(): OperatorFunction<S | T, S | S[] | Page<S>> {
    return map((data) => {
        if (data instanceof ArrayOf && !(data instanceof Page)) {
            return data.items;
        }

        return data;
    });
}

export function deserializeAndValidateResult<S>(returnType: ReturnType<S>): OperatorFunction<Object, S> {
    return pipe(
        map((data) => deserialize(data, returnType)),
        assertValueIsExpectedType(returnType),
        validateAndThrow(),
        unwrapArrays(),
    );
}
