import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const methods = ['POST', 'PUT', 'PATCH'];

@Injectable({
    providedIn: 'root',
})
export class JsonLdInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let newHeaders = request.headers.set('Accept', 'application/ld+json');

        if (methods.includes(request.method) && !(request.body instanceof FormData)) {
            // The browser automatically sets the Content-Type in the case of
            // a multipart form hence we avoid to set the content type in this
            // case.
            newHeaders = newHeaders.set('Content-Type', 'application/json');
        }

        return next.handle(request.clone({
            headers: newHeaders,
        }));
    }
}
