import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ReturnType, ReturnValue } from './http-client-types';
import { HttpClient } from './http-client.service';
import { Options } from './http-options';

@Injectable({
    providedIn: 'root',
})
export class MemoizingHttpClient extends HttpClient {
    constructor(private http: HttpClient) {
        super();
    }

    get<S>(
        returnType: ReturnType<S>,
        path: string,
        options?: Options,
    ): Observable<ReturnValue<S>> {
        return this.http
            .get(
                returnType,
                path,
                options,
            )
            .pipe(shareReplay(1));
    }

    put<S>(
        _returnType: ReturnType<S>,
        _path: string,
        _body: any,
        _options?: Options,
    ): Observable<ReturnValue<S>> {
        throw new Error('Cannot memoize a PUT call');
    }

    post<S>(
        _returnType: ReturnType<S>,
        _path: string,
        _body: any,
        _options?: Options,
    ): Observable<ReturnValue<S>> {
        throw new Error('Cannot memoize a POST call');
    }

    delete<S>(
        _returnType: ReturnType<S>,
        _path: string,
        _options?: Options,
    ): Observable<ReturnValue<S>> {
        throw new Error('Cannot memoize a DELETE call');
    }
}
