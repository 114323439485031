import { Exclude, Expose } from 'class-transformer';
import { Constructor } from './../http-client.service';
import { Array } from './array.model';

// @dynamic
@Exclude()
export class Page<S> extends Array<S> {

    @Expose({ name: 'hydra:totalItems' })
    private _totalCount = 0;

    get totalCount(): number {
        return this._totalCount;
    }

    @Expose({ name: 'hydra:view' })
    set view(view: any) {
        if (view && view['hydra:last'] && view['hydra:first']) {
            const pageMatch = view['@id'].match(/page=(\d+)/);

            if (null !== pageMatch) {
                const rawPageNumber = pageMatch[1];
                const pageNumber = +rawPageNumber;

                if (Number.isNaN(pageNumber)) {
                    throw new Error(`Expected page number to be a number. Got: ${rawPageNumber}`);
                }

                this._number = rawPageNumber;
            } else {
                this._number = 1;
            }

            this._hasNext = view['@id'] !== view['hydra:last'];
            this._hasPrevious = view['@id'] !== view['hydra:first'];
        } else {
            this._number = 1;
            this._hasNext = false;
            this._hasPrevious = false;
        }
    }

    private _number = 1;

    get number(): number {
        return this._number;
    }

    private _hasPrevious = false;

    get hasPrevious(): boolean {
        return this._hasPrevious;
    }

    private _hasNext = false;

    get hasNext(): boolean {
        return this._hasNext;
    }

    static withItems<S>(
        items: S[],
        totalCount?: number,
        number?: number,
        hasPrevious?: boolean,
        hasNext?: boolean,
    ): Page<S> {
        const page = <Page<S>>super.withItems(items);

        page._totalCount = totalCount || 0;
        page._number = undefined !== number ? number : 1;
        page._hasPrevious = undefined !== hasPrevious ? hasPrevious : false;
        page._hasNext = undefined !== hasNext ? hasNext : false;

        return page;
    }

    static ofType<S>(itemType: Constructor<S>): Page<S> {
        return <Page<S>>super.ofType(itemType);
    }
}
