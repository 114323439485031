import { TransformationType, TransformFnParams } from 'class-transformer';

export function dateToString({ value, type }: TransformFnParams): any {
    if (null === value || undefined === value) {
        return value;
    }

    if (TransformationType.PLAIN_TO_CLASS !== type) {
        return (value as Date).toISOString();
    }

    const date = new Date(value as string);

    if (isNaN(date.getTime())) {
        throw new Error(`The string "${value}" is not a valid date`);
    }

    return date;
}
