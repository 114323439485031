import { Exclude, Expose, Type, TypeHelpOptions } from 'class-transformer';
import { ValidateNested } from 'class-validator';
import { Constructor } from './../http-client.service';

function itemConstructor<S>(options: TypeHelpOptions | undefined): Constructor<S> | Function {
    const newObject = options?.newObject;

    if (newObject instanceof Array && undefined !== newObject.itemType) {
        return newObject.itemType;
    }

    return () => {
    };
}

// @dynamic
@Exclude()
export class Array<S> {

    @Expose({ name: 'hydra:member' })
    @Type(itemConstructor)
    private _items: S[] = [];

    @ValidateNested()
    get items(): S[] {
        return this._items;
    }

    private _itemType?: Constructor<S>;

    get itemType(): Constructor<S> | undefined {
        return this._itemType;
    }

    static withItems<S>(items: S[]): Array<S> {
        const array = new this<S>();

        array._items = items;
        array._itemType = items.length > 0
            ? Object.getPrototypeOf(items[0]).constructor
            : undefined;

        return array;
    }

    static ofType<S>(itemType: Constructor<S>): Array<S> {
        const array = new this<S>();

        array._items = [];
        array._itemType = itemType;

        return array;
    }

}
