import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

export class UrlPrefixInterceptor implements HttpInterceptor {
    private readonly urlPrefix: string;

    constructor(urlPrefix: string) {
        this.urlPrefix = urlPrefix;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const prefixedRequest = request.clone({
            url: this.urlPrefix + request.url,
        });

        return next.handle(prefixedRequest);
    }
}
