import 'reflect-metadata';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, Provider, SkipSelf } from '@angular/core';
import { HTTP_CLIENT_OPTIONS } from './http-client-options.token';
import { HttpClient } from './http-client.service';
import { JsonLdInterceptor } from './interceptors/json-ld.interceptor';
import { UrlPrefixInterceptor } from './interceptors/url-prefix.interceptor';
import { SerializingHttpClient } from './serializing-http-client.service';

export interface HttpClientOptions {
    apiUrl: string;
}

interface HttpClientModuleOptionsWithProvider {
    optionsProvider: Provider;
}

interface HttpClientModuleOptionsWithConfig {
    config: HttpClientOptions;
}

export type EmptyHttpClientModuleOptions = {};

export type HttpClientModuleOptions =
    HttpClientModuleOptionsWithProvider
    | HttpClientModuleOptionsWithConfig
    | EmptyHttpClientModuleOptions;

function isHttpClientModuleOptionsWithProvider(options: HttpClientModuleOptions): options is HttpClientModuleOptionsWithProvider {
    return (options as HttpClientModuleOptionsWithProvider).optionsProvider !== undefined;
}

function isHttpClientModuleOptionsWithConfig(options: HttpClientModuleOptions): options is HttpClientModuleOptionsWithConfig {
    return (options as HttpClientModuleOptionsWithConfig).config !== undefined;
}

function registerOptionProviders(
    options: HttpClientModuleOptions,
    originalProviders: Provider[],
): Provider[] {
    const providers = originalProviders.slice();

    // We only register UrlPrefixInterceptor if a configuration is provided
    if (!isHttpClientModuleOptionsWithProvider(options) && !isHttpClientModuleOptionsWithConfig(options)) {
        return providers;
    }

    providers.push({
        provide: HTTP_INTERCEPTORS,
        useFactory: (nestedOptions: HttpClientOptions) => new UrlPrefixInterceptor(nestedOptions.apiUrl),
        deps: [HTTP_CLIENT_OPTIONS],
        multi: true,
    });

    if (isHttpClientModuleOptionsWithProvider(options)) {
        providers.push(options.optionsProvider);
    } else if (isHttpClientModuleOptionsWithConfig(options)) {
        providers.push({
            provide: HTTP_CLIENT_OPTIONS,
            useValue: options.config,
        });
    } else {
        throw Error(`Unknown options ${options}`);
    }

    return providers;
}

@NgModule()
export class HttpClientModule {
    constructor(@Optional() @SkipSelf() parentModule: HttpClientModule) {
        if (parentModule) {
            throw new Error(
                'HttpClientModule is already loaded. It should only be imported in your application\'s main module.',
            );
        }
    }

    static forRoot(options: HttpClientModuleOptions): ModuleWithProviders<HttpClientModule> {
        const reversedProviders: Provider[] = registerOptionProviders(
            options,
            [
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: JsonLdInterceptor,
                    multi: true,
                },
                {
                    provide: HttpClient,
                    useClass: SerializingHttpClient,
                },
            ],
        );

        return {
            ngModule: HttpClientModule,
            providers: reversedProviders.reverse(),
        };
    }
}
