import { Type } from 'class-transformer';
import { IsArray, IsDefined, ValidateNested } from 'class-validator';
import { Expression } from '@app/shared/models/assessment/expression.model';
import { IsNullable } from 'projects/http-client/src/lib/decorators/is-nullable.decorator';
import { ExpressionValueShare } from "@app/shared/models/assessment/expression-value-share.model";

export class ExpressionValue {
    @IsDefined()
    @ValidateNested()
    @Type(() => Expression)
    expression!: Expression;

    @IsDefined()
    @IsNullable()
    value!: number | boolean | string | null;

    @IsDefined({ each: true })
    @IsArray()
    @ValidateNested()
    @Type(() => ExpressionValueShare)
    shares!: ExpressionValueShare[];
}
