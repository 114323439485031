import { Observable } from 'rxjs';
import { ReturnType, ReturnValue } from './http-client-types';
import { Options } from './http-options';
import { Array as ArrayOf } from './models/array.model';
import { Page } from './models/page.model';

export type Constructor<T> = new () => T;

// We use an abstract class here since types are removed at compilation time
// hence cannot be registered to the Angular DI.
export abstract class HttpClient {
    abstract get<S>(
        returnType: null,
        path: string,
        options?: Options,
    ): Observable<any>;

    abstract get<S>(
        returnType: Constructor<S>,
        path: string,
        options?: Options,
    ): Observable<S>;

    abstract get<S>(
        returnType: Page<S>,
        path: string,
        options?: Options,
    ): Observable<Page<S>>;

    abstract get<S>(
        returnType: ArrayOf<S>,
        path: string,
        options?: Options,
    ): Observable<S[]>;

    abstract get<S>(
        returnType: ReturnType<S>,
        path: string,
        options?: Options,
    ): Observable<ReturnValue<S>>;

    abstract put<S>(
        returnType: null,
        path: string,
        body: any,
        options?: Options,
    ): Observable<any>;

    abstract put<S>(
        returnType: Constructor<S>,
        path: string,
        body: any,
        options?: Options,
    ): Observable<S>;

    abstract put<S>(
        returnType: Page<S>,
        path: string,
        body: any,
        options?: Options,
    ): Observable<Page<S>>;

    abstract put<S>(
        returnType: ArrayOf<S>,
        path: string,
        body: any,
        options?: Options,
    ): Observable<S[]>;

    abstract put<S>(
        returnType: ReturnType<S>,
        path: string,
        body: any,
        options?: Options,
    ): Observable<ReturnValue<S>>;

    abstract post<S>(
        returnType: null,
        path: string,
        body: any,
        options?: Options,
    ): Observable<any>;

    abstract post<S>(
        returnType: Constructor<S>,
        path: string,
        body: any,
        options?: Options,
    ): Observable<S>;

    abstract post<S>(
        returnType: Page<S>,
        path: string,
        body: any,
        options?: Options,
    ): Observable<Page<S>>;

    abstract post<S>(
        returnType: ArrayOf<S>,
        path: string,
        body: any,
        options?: Options,
    ): Observable<S[]>;

    abstract post<S>(
        returnType: ReturnType<S>,
        path: string,
        body: any,
        options?: Options,
    ): Observable<ReturnValue<S>>;

    abstract delete<S>(
        returnType: null,
        path: string,
        options?: Options,
    ): Observable<any>;

    abstract delete<S>(
        returnType: Constructor<S>,
        path: string,
        options?: Options,
    ): Observable<S>;

    abstract delete<S>(
        returnType: Page<S>,
        path: string,
        options?: Options,
    ): Observable<Page<S>>;

    abstract delete<S>(
        returnType: ArrayOf<S>,
        path: string,
        options?: Options,
    ): Observable<S[]>;

    abstract delete<S>(
        returnType: ReturnType<S>,
        path: string,
        options?: Options,
    ): Observable<ReturnValue<S>>;
}
